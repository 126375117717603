<template>
  <notifications position="bottom right" />
  <router-view/>
</template>

<style>
body{
  background-color: #f3f4f6
}
</style>
