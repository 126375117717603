<template>
<div v-if="loading && !userdata?.email" class="flex h-screen items-center justify-center w-full h-full">
	<div class="flex justify-center items-center space-x-1 text-sm text-gray-700">
				<svg fill='none' class="w-6 h-6 animate-spin" viewBox="0 0 32 32" xmlns='http://www.w3.org/2000/svg'>
					<path clip-rule='evenodd'
						d='M15.165 8.53a.5.5 0 01-.404.58A7 7 0 1023 16a.5.5 0 011 0 8 8 0 11-9.416-7.874.5.5 0 01.58.404z'
						fill='currentColor' fill-rule='evenodd' />
				</svg>
		<div>Aguarde ...</div>
	</div>
</div>
<div v-else class="min-h-full">
  <nav class="fixed w-full bg-gray-800">
    <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <div class="flex h-16 items-center justify-between">
        <div class="flex items-center">
          <div class="flex-shrink-0">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8 text-blue-500">
        
  <path stroke-linecap="round" stroke-linejoin="round" d="M8.625 12a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375M21 12c0 4.556-4.03 8.25-9 8.25a9.764 9.764 0 01-2.555-.337A5.972 5.972 0 015.41 20.97a5.969 5.969 0 01-.474-.065 4.48 4.48 0 00.978-2.025c.09-.457-.133-.901-.467-1.226C3.93 16.178 3 14.189 3 12c0-4.556 4.03-8.25 9-8.25s9 3.694 9 8.25z" />
</svg>


          </div>
          <div class="hidden md:block">
            <div class="ml-10 flex items-baseline space-x-4">
              <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" -->
              <a @click="$router.push('/')" :class="rota.currentRoute.name == 'home' ? 'cursor-pointer bg-gray-900 text-white rounded-md px-3 py-2 text-sm font-medium':'cursor-pointer text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium'" aria-current="page">Dashboard</a>
              <a @click="$router.push('/config')" :class="rota.currentRoute.name == 'config' ? 'cursor-pointer bg-gray-900 text-white rounded-md px-3 py-2 text-sm font-medium':'cursor-pointer text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium'">Configurações</a>
              <a v-if="userdata.admin" @click="$router.push('/admin')" :class="rota.currentRoute.name.match('admin') ? 'cursor-pointer bg-gray-900 text-white rounded-md px-3 py-2 text-sm font-medium':'cursor-pointer text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium'" aria-current="page">Administrativo</a>
            </div>
          </div>
        </div>
        <div class="hidden md:block">
          <div class="ml-4 flex items-center md:ml-6">
            <span v-if="userdata.email" :class="userdata.connect ? 'border ml-5 border-green-900 text-green-300 rounded-md px-3 py-2 text-sm float-right font-medium' : 'border ml-5 border-red-900 text-red-300 rounded-md px-3 py-2 text-sm float-right font-medium'" aria-current="page">{{ userdata?.connect ? "Sessão conectada":"Sessão desconectada" }}</span>
            <span @click="openMenu"  class="ml-5 cursor-pointer relative rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
               {{user?.name}}
            </span>

            <!-- Profile dropdown -->
            <div class="relative ml-3">
              <div>
                <button @click="openMenu" type="button" class="relative flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800" id="user-menu-button" aria-expanded="false" aria-haspopup="true">
                  <img class="h-8 w-8 rounded-full" :src="user?.picture" alt="">
                </button>
              </div>

              <!--
                Dropdown menu, show/hide based on menu state.

                Entering: "transition ease-out duration-100"
                  From: "transform opacity-0 scale-95"
                  To: "transform opacity-100 scale-100"
                Leaving: "transition ease-in duration-75"
                  From: "transform opacity-100 scale-100"
                  To: "transform opacity-0 scale-95"
              -->
              <div v-show="menu" class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="user-menu-button" tabindex="-1">
                <!-- Active: "bg-gray-100", Not Active: "" -->
                <div @click="logout" class="block px-4 py-2 text-sm text-gray-700 cursor-pointer" role="menuitem" tabindex="-1" id="user-menu-item-2">Sair</div>
              </div>
            </div>
          </div>
        </div>
        <div class="-mr-2 flex md:hidden">
          <!-- Mobile menu button -->
          <button @click="openMobile()" type="button" class="relative inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800" aria-controls="mobile-menu" aria-expanded="false">
            <span class="absolute -inset-0.5"></span>
            <span class="sr-only">Open main menu</span>
            <!-- Menu open: "hidden", Menu closed: "block" -->
            <svg class="block h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
            </svg>
            <!-- Menu open: "block", Menu closed: "hidden" -->
            <svg class="hidden h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
      </div>
    </div>

    <!-- Mobile menu, show/hide based on menu state. -->
    <div class="md:hidden" v-if="mobile">
      <div class="space-y-1 px-2 pb-3 pt-2 sm:px-3">
        <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" -->
        <a href="#" class="bg-gray-900 text-white block rounded-md px-3 py-2 text-base font-medium" aria-current="page">{{title}}</a>
        <!--<a href="#" class="text-gray-300 hover:bg-gray-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium">Team</a> -->
      </div>
      <div class="border-t border-gray-700 pb-3 pt-4">
        <div class="flex items-center px-5">
          <div class="flex-shrink-0">
            <img class="h-10 w-10 rounded-full" :src="user?.picture" alt="">
          </div>
          <div class="ml-3">
            <div class="text-sm font-medium leading-none text-gray-400">{{user?.name}}</div>
          </div>
        </div>
        <div class="mt-3 space-y-1 px-2">
          <a @click="$router.push('/');mobile = false" class="cursor-pointer block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white">Dashboard</a>
          <a @click="$router.push('/config');mobile = false" class="cursor-pointer block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white">Configurações</a>
          <a v-if="userdata.admin" @click="$router.push('/admin');mobile = false" class="cursor-pointer block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white">Administrativo</a>
          <a @click="logout()" class="cursor-pointer block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white">Sair</a>
        </div>
      </div>
    </div>
  </nav>

  <header class="bg-white w-full shadow">
    <div class="mx-auto flex justify-between max-w-7xl px-4 py-8 h-46 sm:px-6 lg:px-8">
      <div class="flex">
      <h1 class="text-3xl font-bold tracking-tight text-gray-900 mt-14">{{title}}</h1>
      </div>
      <div class="flex mt-14" v-if="userdata.vencimento > Date.now()">
        <span class="ml-3 hidden sm:block">
          <button @click="disconnect" type="button" class="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 mr-2">
  <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
</svg>

            Desconectar sessão
          </button>
        </span>

        <span class="sm:ml-3 hidden sm:block">
          <button @click="start" type="button" class="inline-flex items-center rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 mr-2">
  <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
</svg>

            Iniciar sessão
          </button>
        </span>

      </div>
    </div>
  </header>
  <main>
    <div class="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
      <slot />
    </div>
  </main>
</div>
</template>

<script>
import { useAuth0 } from '@auth0/auth0-vue';
import api from '@/api/axios.js'

export default {
   props: {
      title: String
   },
   data(){
      return{
         menu: false,
         rota: this.$router,
         loading: true,
         userdata: {},
         mobile: false
      }
   },
   methods: {
      openMenu() {
         this.menu = this.menu? false : true
      },
      openMobile() {
         this.mobile = this.mobile? false : true
      },
      async getUser() {

        const token = await this.token()
        
        const res = await api.getInfo(token)

        this.loading = false
        this.$emit('user', res)

        this.userdata = res
      },
      async start() {

        if(!this.userdata.prompt){
          return this.$notify({type: 'warn', text: 'Configure antes de iniciar!'})
        }

        const token = await this.token()
        
        try{
          this.$router.push('/qrcode')
          const resp = await api.connectSession(token)

          if(resp.status == 201){
            this.$notify({type: 'warn', text: 'Você já tem uma conexão iniciada, aguarde expirar para criar outra!'})
          }else if(resp.status == 202){
            this.$notify({type: 'success', text: 'Você já está conectado!'})
          }
        }catch(err){
            this.$notify({type: 'error', text: 'Aconteceu um erro inesperado!'})
        }
      },
      async disconnect(){
        const token = await this.token()
        try{
          await api.disconnectSession(token)
          if(this.$route.name == 'qrcode'){
            this.$router.push('/')
          }
          this.userdata.connect = false
          this.$notify({type: 'success', text: 'Desconectado com sucesso!'})
        }catch(err){
              this.$notify({type: 'error', text: 'Aconteceu um erro inesperado!'})
          }
      }
   },
   mounted(){
    if(!this.isLoading){
      this.getUser()
    }
   }, 
  setup() {
    const { loginWithRedirect, isLoading, user, logout, getAccessTokenSilently, isAuthenticated } = useAuth0()

      if(!user._rawValue){
         loginWithRedirect()
      }

      return {
        logout: () => {
         logout({ logoutParams: { returnTo: window.location.origin } });
        },
        login: () => {
          loginWithRedirect()
        },
        token: async () => {
          return await getAccessTokenSilently()
        },
        user,
        isLoading,
        isAuthenticated
      };
   },
   watch: {
    async isLoading(){
      if(!this.isAuthenticated){
        this.login()
      }else{
        this.getUser()
      }
    }
   }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
