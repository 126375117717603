<template>
  <navBar title="Configure" @user="user"> 
    <div v-if="!userdata">
      <div class="flex justify-center items-center space-x-1 text-sm text-gray-700">
				<svg fill='none' class="w-6 h-6 animate-spin" viewBox="0 0 32 32" xmlns='http://www.w3.org/2000/svg'>
					<path clip-rule='evenodd'
						d='M15.165 8.53a.5.5 0 01-.404.58A7 7 0 1023 16a.5.5 0 011 0 8 8 0 11-9.416-7.874.5.5 0 01.58.404z'
						fill='currentColor' fill-rule='evenodd' />
				</svg>
        <div>Aguarde ...</div>
      </div>
    </div>
    <section v-else class="bg-gray-100">
  <div class="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
    <div class="grid grid-cols-1 gap-x-16 gap-y-8 lg:grid-cols-5">
      <div class="lg:col-span-2 lg:py-12">
        <p class="max-w-xl text-lg">
          Caso você tenha alguma dúvida, siga os passos do vídeo a baixo:
        </p>
        <iframe class="w-full mt-5" height="215" src="https://www.youtube.com/embed/a9Q4pfGwOtI?si=Jx-ais4EPjPetwf9" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </div>

      <div class="rounded-lg bg-white p-8 shadow-lg lg:col-span-3 lg:p-12">
        <div class="space-y-4">
          <div>
            <label class="text-gray-600 text-sm" for="name">Digite seu número WhatsApp com DDD:</label>
            <input
              v-model="userdata.whatsapp"
              class="w-full rounded-lg mt-2 border border-gray-200 p-3 text-sm"
              placeholder="000000000"
              type="number"
              id="name"
            />
          </div>
          <hr />
          <div>
            <label class="text-gray-600 text-sm" for="name">Prompt OpenAI:</label>
            <textarea
              v-model="userdata.prompt"
              class="w-full h-32 rounded-lg mt-2 border border-gray-200 p-3 text-sm"
              type="text"
              id="name"
            ></textarea>
          </div>

          <div>
            <label class="text-gray-600 text-sm" for="name">Key OpenAI:</label>
            <input
              v-model="userdata.key"
              class="w-full rounded-lg mt-2 border border-gray-200 p-3 text-sm"
              placeholder="sk-"
              type="text"
            />
          </div>

          <div>
            <label class="text-gray-600 text-sm" for="name">Palavra chave de finalização:</label>
            <input
            v-model="userdata.palavrachave"
              class="w-full rounded-lg mt-2 border border-gray-200 p-3 text-sm"
              type="text"
            />
          </div>

          <div class="mt-4 w-full">
            <button
              @click="save"
              class="inline-block w-full float-right rounded-lg bg-gray-800 hover:bg-gray-900 mt-2 px-5 py-3 font-medium text-white sm:w-auto"
            >
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
  </navBar>
</template>

<script>
// @ is an alias to /src
import navBar from '@/components/navBar.vue'
import api from '@/api/axios'

export default {
  name: 'homeView',
  data() {
    return {
      userdata: null
    }
  },
  components: {
    navBar
  },
  methods: {
    proximo(value) {
      console.log(value)
    },
    user(value){
      this.userdata = value
    },
    async save(){

        if(!this.userdata.whatsapp || !this.userdata.key || !this.userdata.palavrachave || !this.userdata.prompt){
          return this.$notify({type: 'warn', text: 'Preencha todos os campos.'})
        }
        
        if(String(this.userdata.whatsapp).length < 10 || String(this.userdata.whatsapp).length > 15){
          return this.$notify({type: 'warn', text: 'Preencha com um número válido.'})
        }

        try{
          await api.updateInfo(this.userdata.token, { whatsapp: this.userdata.whatsapp, key: this.userdata.key, palavrachave: this.userdata.palavrachave, prompt: this.userdata.prompt})
          this.$notify({type: 'success', text: 'Salvo com sucesso!'})
        }catch{
          this.$notify({type: 'error', text: 'Aconteceu um erro inesperado!'})
        }
    }
  }
}
</script>
