<template>
  <navBar title="Dashboard" @user="user">
    <iframe v-if="userdata" :src="'https://api.bot.avanso.com.br/qrcode?id='+userdata.qrcodeid" class="w-full mt-12 h-96" />
  </navBar>
</template>

<script>
// @ is an alias to /src
import navBar from '@/components/navBar.vue'

export default {
  name: 'homeView',
  data() {
    return {
      vencimento: 'loading',
      userdata: {}
    }
  },
  components: {
    navBar
  },
  methods: {
    proximo(value) {
      console.log(value)
    },
    user(value){
      console.log(value)
      this.userdata = value
    }
  },
}
</script>
