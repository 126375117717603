<template>
  <navBar title="Admin" @user="user">
    <div v-if="users?.length == 0">
      <div class="flex justify-center items-center space-x-1 text-sm text-gray-700">
				<svg fill='none' class="w-6 h-6 animate-spin" viewBox="0 0 32 32" xmlns='http://www.w3.org/2000/svg'>
					<path clip-rule='evenodd'
						d='M15.165 8.53a.5.5 0 01-.404.58A7 7 0 1023 16a.5.5 0 011 0 8 8 0 11-9.416-7.874.5.5 0 01.58.404z'
						fill='currentColor' fill-rule='evenodd' />
				</svg>
        <div>Aguarde ...</div>
      </div>
    </div>
    <div v-else class="w-full flex justify-center items-center">
<ul role="list" class="divide-y divide-gray-100 bg-white w-full p-5 border">
  <li v-for="index in users" :key="index" @click="editUser(index._id)" class="cursor-pointer flex justify-between gap-x-6 py-5">
    <div class="flex min-w-0 gap-x-4">
      <img class="h-12 w-12 flex-none rounded-full bg-gray-50" :src="index.picture" alt="">
      <div class="min-w-0 flex-auto">
        <p class="text-sm font-semibold leading-6 text-gray-900">{{index.email}} {{Number(index.vencimento) > Date.now() ? '(Assinante)':'(Não assinante)'}}</p>
        <p class="mt-1 truncate text-xs leading-5 text-gray-500">{{index.whatsapp ? index.whatsapp : 'Não configurou o WhatsApp'}}</p>
      </div>
    </div>
    <div class="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
      <p class="mt-1 text-xs leading-5 text-gray-500">Vencimento: {{ index.vencimento ? new Date(Number(index.vencimento)).toLocaleString('pt-BR') : 'Não assinante'}}</p>
    </div>
  </li>
</ul>
    </div>
  </navBar>
</template>

<script>
// @ is an alias to /src
import navBar from '@/components/navBar.vue'
import api from '@/api/axios'

export default {
  name: 'AdminView',
  data() {
    return {
      userdata: {},
      users: []
    }
  },
  components: {
    navBar
  }, 
  methods: {
    proximo(value) {
      console.log(value)
    },
    async user(value){
      this.userdata = value
      try{
      var users = await api.getUsers(value.token)
      }catch{
        this.$router.push('/')
      }
      this.users = users
    },
    editUser(value){

      this.$router.push({name: 'adminedit', params: {id: value}})
      console.log(value)
    }
  },
}
</script>
