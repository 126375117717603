import axios from 'axios'

const tenant = 1
const url = "https://api.bot.avanso.com.br"

const Main = {
    async getInfo(token) { 
        const {data} = await axios.post(url+'/getinfo', {}, {
            headers: {
            'Content-Type': 'multipart/form-data',
            'authorization': token,
            'tenant': tenant
            }
        })

        data.token = token

        return data
    },
    async updateInfo(token, { whatsapp, key, palavrachave, prompt}) { 
        const {data} = await axios.post(url+'/updateinfo', { whatsapp, key, palavrachave, prompt }, {
            headers: {
            'authorization': token,
            'tenant': tenant
            }
        })

        return data
    },
    async getUsers(token) { 
        const {data} = await axios.post(url+'/getusers', {}, {
            headers: {
            'authorization': token,
            'tenant': tenant
            }
        })

        return data
    },
    async getUser(token, id) { 
        const {data} = await axios.post(url+'/getuser', {user_id: id}, {
            headers: {
            'authorization': token,
            'tenant': tenant
            }
        })

        return data
    },
    async updateUser(token, id, { whatsapp, key, palavrachave, prompt, vencimento}) { 
        const {data} = await axios.post(url+'/updateuser', {user_id: id, whatsapp, key, palavrachave, prompt, vencimento}, {
            headers: {
            'authorization': token,
            'tenant': tenant
            }
        })

        return data
    },
    async connectSession(token) { 
        const data = await axios.post(url+'/connect', {}, {
            headers: {
            'authorization': token,
            'tenant': tenant
            }
        })

        return data
    },
    async disconnectSession(token) { 
        const data = await axios.post(url+'/disconnect', {}, {
            headers: {
            'authorization': token,
            'tenant': tenant
            }
        })

        return data
    }
}

export default Main