<template>
  <navBar title="Admin" @user="user">
    <div v-if="!usuario?.email">
      <div class="flex justify-center items-center space-x-1 text-sm text-gray-700">
				<svg fill='none' class="w-6 h-6 animate-spin" viewBox="0 0 32 32" xmlns='http://www.w3.org/2000/svg'>
					<path clip-rule='evenodd'
						d='M15.165 8.53a.5.5 0 01-.404.58A7 7 0 1023 16a.5.5 0 011 0 8 8 0 11-9.416-7.874.5.5 0 01.58.404z'
						fill='currentColor' fill-rule='evenodd' />
				</svg>
        <div>Aguarde ...</div>
      </div>
    </div>
    <section v-else class="bg-gray-100">
  <div class="mx-auto w-full px-4 py-8 sm:px-6 lg:px-8">
    <span @click="this.$router.push('/admin')" class="cursor-pointer"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
</svg></span>
    <div class="">
      <div class="rounded-lg max-w-xl bg-white mx-auto pb-24 p-2 lg:p-12 lg:pb-24 shadow-lg">
        <div>
          <img class="h-12 w-12 flex-none rounded-full bg-gray-50 mx-auto mb-3" :src="usuario.picture" alt="">
            <div class="w-full"><span class="text-center">{{usuario.email}}</span></div>
          <div class="mt-3">
            <label class="text-gray-600 text-sm" for="name">WhatsApp:</label>
            <input
              v-model="usuario.whatsapp"
              class="w-full rounded-lg mt-2 border border-gray-200 p-3 text-sm"
              placeholder="000000000"
              type="number"
              id="name"
            />
          </div>
          <hr class="mt-3 mb-3"/>
          <div>
            <label class="text-gray-600 text-sm" for="name">Prompt OpenAI:</label>
            <textarea
              v-model="usuario.prompt"
              class="w-full h-32 rounded-lg mt-2 border border-gray-200 p-3 text-sm"
              type="text"
              id="name"
            ></textarea>
          </div>

          <div>
            <label class="text-gray-600 text-sm" for="name">Key OpenAI:</label>
            <input
              v-model="usuario.key"
              class="w-full rounded-lg mt-2 border border-gray-200 p-3 text-sm"
              placeholder="sk-"
              type="text"
            />
          </div>

          <div class="mt-1">
            <label class="text-gray-600 text-sm" for="name">Palavra chave de finalização:</label>
            <input
            v-model="usuario.palavrachave"
              class="w-full rounded-lg mt-2 border border-gray-200 p-3 text-sm"
              type="text"
            />
          </div>

          <div class="mt-1">
            <label class="text-gray-600 text-sm" for="name">Vencimento:</label>
            <input
            v-model="usuario.vencimento"
              class="w-full rounded-lg mt-2 border border-gray-200 p-3 text-sm"
              type="datetime-local"
            />
          </div>

          <div class="mt-4 w-full">
            <button
              @click="save"
              class="inline-block w-full float-right rounded-lg bg-gray-800 hover:bg-gray-900 mt-2 px-5 py-3 font-medium text-white sm:w-auto"
            >
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
  </navBar>
</template>

<script>
// @ is an alias to /src
import navBar from '@/components/navBar.vue'
import api from '@/api/axios'

export default {
  name: 'AdminView',
  data() {
    return {
      userdata: {},
      usuario: {}
    }
  },
  components: {
    navBar
  }, 
  methods: {
    proximo(value) {
      console.log(value)
    },
    async user(value){
      this.userdata = value
      try{
      var user = await api.getUser(value.token,this.$route.params.id)
      }catch{
        this.$router.push('/admin')
      }
      this.usuario = user

      const timestamp = Number(this.usuario.vencimento); // Por exemplo, 12 de junho de 2018 às 19:30 em milissegundos

      const date = new Date(timestamp);

      // Extraia os componentes da data e hora
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Mês é base zero
      const day = date.getDate().toString().padStart(2, '0');
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');

      // Crie a string formatada
      const formattedTimestamp = `${year}-${month}-${day}T${hours}:${minutes}`;


      this.usuario.vencimento = this.usuario.vencimento ? formattedTimestamp : null
    },
    async save(){


      try{
        await api.updateUser(this.userdata.token, this.$route.params.id, { whatsapp: this.usuario.whatsapp, key: this.usuario.key, palavrachave: this.usuario.palavrachave, prompt: this.usuario.prompt, vencimento: this.usuario.vencimento ? new Date(this.usuario.vencimento).getTime() : null})
        this.$notify({type: 'success', text: 'Salvo com sucesso!'})
      }catch(err){
        console.log(err)
        this.$notify({type: 'error', text: 'Aconteceu um erro inesperado!'})
      }
      }
  },
}
</script>
