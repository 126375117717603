<template>
  <navBar title="Dashboard" @user="user">
    <div v-if="userdata.email" class="w-full flex justify-center items-center">
      <div v-if="!userdata?.vencimento && userdata.vencimento !== 'loading'" class="bg-white p-20 border w-full text-center">
        <span>😄 Agradecemos pelo seu cadastro, solicite seu teste grátis agora mesmo.</span>
        <div class="mt-10"><a href="https://api.whatsapp.com/send/?phone=551930941248&text=Ol%C3%A1,%20quero%20solicitar%20o%20teste%20gr%C3%A1tis%20do%20gochatbot" target="_blank" class="bg-green-500 cursor-pointer p-3 text-white hover:bg-green-600 rounded-md">Clique aqui para solicitar</a></div>
      </div>
      <div v-else-if="userdata.vencimento !== 'loading' && userdata.vencimento < Date.now()" class="bg-white p-20 border w-full text-center">
        😢 Sua assinatura expirou no dia {{new Date(Number(userdata.vencimento)).toLocaleString('pt-BR')}}, renove o seu plano.
      </div>
      <div v-else class="bg-white p-20 border w-full text-center">
        🎉 Você um assinante, o vencimento da sua assinatura é no dia {{new Date(Number(userdata.vencimento)).toLocaleString('pt-BR')}}.
      </div>
    </div>
    <div v-else>
      <div class="flex justify-center items-center space-x-1 text-sm text-gray-700">
				<svg fill='none' class="w-6 h-6 animate-spin" viewBox="0 0 32 32" xmlns='http://www.w3.org/2000/svg'>
					<path clip-rule='evenodd'
						d='M15.165 8.53a.5.5 0 01-.404.58A7 7 0 1023 16a.5.5 0 011 0 8 8 0 11-9.416-7.874.5.5 0 01.58.404z'
						fill='currentColor' fill-rule='evenodd' />
				</svg>
        <div>Aguarde ...</div>
      </div>
    </div>
  </navBar>
</template>

<script>
// @ is an alias to /src
import navBar from '@/components/navBar.vue'

export default {
  name: 'homeView',
  data() {
    return {
      vencimento: 'loading',
      userdata: {}
    }
  },
  components: {
    navBar
  },
  methods: {
    proximo(value) {
      console.log(value)
    },
    user(value){
      console.log(value)
      this.userdata = value
    }
  },
}
</script>
