import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ConfigView from '../views/ConfigView.vue'
import AdminView from '../views/AdminView.vue'
import AdminEditView from '../views/AdminEditView.vue'
import QrCodeView from '../views/QrCodeView.vue'


const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/config',
    name: 'config',
    component: ConfigView
  },
  {
    path: '/admin',
    name: 'admin',
    component: AdminView
  },
  {
    path: '/admin/:id',
    name: 'adminedit',
    component: AdminEditView
  },
  {
    path: '/qrcode',
    name: 'qrcode',
    component: QrCodeView
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


export default router
