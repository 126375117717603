import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createAuth0 } from '@auth0/auth0-vue';
import '@/assets/tailwind.css'
import Notifications from '@kyvg/vue3-notification'

const app = createApp(App)

app
.use(Notifications)
.use(router)
.use(createAuth0({
  domain: "dev-ho6u2ilqtbsypw77.us.auth0.com",
  clientId: "9dosVh3uyJYae7WOsShpv1KbaiAzd9ST",
  authorizationParams: {
    redirect_uri: window.location.origin
  }
}))
.mount('#app')
